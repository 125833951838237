import React, { useEffect } from "react";
import { handleAuthentication } from "../utils/auth";

const Callback = () => {
    useEffect(() => {
        handleAuthentication();
    }, []);

    return <p>Loading...</p>;
};

export default Callback;
